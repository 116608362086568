.content-body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}

.logo-container {
  text-align: center;
  padding-top: 2em;
}
.logo-container a {
  padding: 1.5em;
}
.cl-h1{
  font-size: 3em;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1em;
}
.cl-h3{
  font-size: 1.5em;
  font-weight: lighter;
  padding: 0 10px 0 10px;
}
.git-icon:hover, .linked-icon:hover, .twitt-icon:hover {
  fill: #fef0e5;
  transition: .5s;
}
.body-container h1{
  font-weight: 700;
}

