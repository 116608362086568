.contact-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-container {
    max-width: 416px;
}
label{
    color: #FEE1CC;
    font-size: 25px;
    font-weight: bold;
}
p {
  text-align: center;
  font-weight: 500;
}
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="submit"] {
  background-color: #fc6d00;
  color: #fef0e5;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: rgba(255, 105, 5, 0.7);
}

@media only screen and (max-width:416px){
  .contact-container{
    padding: 0 50px;
  }
  input[type="submit"] {
    background-color: #fc6d00;
  }
}
