.about-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
    color: white ;
    font-size: 1.5em ;
    text-align: left ;
}
p a{
  color: #fc6d00 !important;
}
p span{
  padding-left: 2em;
}
.about-img img{
  border: 2px solid #fc6d00;
  border-radius: 100%;
  float: left;
}

@media only screen and (max-width:416px){
  .about-img{
    text-align: center;
    margin: 35px 0 50px 0;
  }
  .about-img>img{
    float: none;
  }
  .about-body>p{
    
    letter-spacing: 1.3px !important;
    padding: 0 15px 0 30px !important;
  }
  .about-body>p:last-child{
    margin-bottom: 30px;
  }
}